.timeline-wrapper {
    --bookmark-h-gap: 80px;
    --bookmark-v-gap: 40px;
    --bookmark-l-bg: #d84646;
    --bookmark-r-bg: #000;
    --marker-size: 20px;
    --marker-bg: #29426D ;
    --thread-width: 4px;
    --thread-bg: #F9CF36;
    position: relative;
  }

  .timeline {
    position: relative;
    margin: 0;
    padding: 20px 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr var(--bookmark-h-gap) 1fr;
    grid-row-gap: var(--bookmark-v-gap);
  }

  .timeline-item {
    position: relative;
    color: #F9CF36;
    font-size: 9px;
    font-weight: 300;
    line-height: 11px;
  }

  .timeline-item:nth-child(odd) {
    border-top: dotted;
    border-width: 2px;
    grid-column: 1;
  }

  .timeline-item:nth-child(even) {
    border-top: dotted;
    border-width: 2px;
    grid-column: 3;
  }

  .timeline-wrapper.with-tip .timeline-item:before {
    content: "";
  }

  /* .timeline-item:before {
    position: absolute;
    display: block;
    border: 10px solid transparent;
    top: calc(50% - 10px);
  }

  .timeline-item:nth-child(odd):before {
    border-left-color: var(--bookmark-l-bg);
    left: 100%;
  }

  .timeline-item:nth-child(even):before {
    border-right-color: var(--bookmark-r-bg);
    right: 100%;
  } */

  .timeline-dotmark {
    grid-column: 2 / span 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeline-dot {
    width: 55px;
    height: 55px;
    border: 3px solid var(--marker-bg);
    border-radius: 50%;
    position: relative;
    background-color: #29426D ;
    display: flex;             /* Flexbox pour centrer */
    align-items: center;       /* Centrer verticalement */
    justify-content: center;   /* Centrer horizontalement */

}

.timeline-icone{
    text-align: center;
    color: #F9CF36;
    font-size: 23px;
}

  .timeline-dot::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 2px 10px 15px;
    border-color: transparent transparent transparent #F9CF36;
    position: absolute;
    right: 35px;
    top: 130%;
    transform: rotate(90deg);
  }

  .timeline-dotmark:first-of-type {
    grid-row: 1;
  }

  .timeline-connector {
    position: absolute;
    top: 0;
    left: calc(50% - calc(var(--thread-width) / 2));
    background-color: var(--thread-bg);
    width: var(--thread-width);
    height: 100%;
  }

  @media screen and (max-width: 640px) {
    .timeline {
      grid-template-columns: 0 var(--bookmark-h-gap) 1fr;
    }

    .timeline-item:nth-child(odd) {
      grid-column: 3;
    }

    .timeline-item:nth-child(odd):before {
      border-left: 0;
      left: initial;
      border-right-color: var(--bookmark-l-bg);
      right: 100%;
    }

    .timeline-connector {
      left: calc(calc(var(--bookmark-h-gap) / 2) - calc(var(--thread-width) / 2));
    }
  }




.fade-in-up {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 1s ease-in-out forwards;
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }



.containerR{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .containerR .card{
    margin-left: 20px;
    position: relative;
  }

  .containerR .card .face{
    width:400px;
    height: 300px;
    transition:.4s;

  }

  .containerR .card .face.face1{
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
  }

  .containerR .card:hover .face.face1{
    transform: translateY(0);
    box-shadow:
      inset 0 0 60px whitesmoke,
      inset 20px 0 80px #f0f,
      inset -20px 0 80px #0ff,
      inset 20px 0 300px #f0f,
      inset -20px 0 300px #0ff,
      0 0 50px #fff,
      -10px 0 80px #f0f,
      10px 0 80px #0ff;

  }


  .containerR .card .face.face1 .content{
    opacity: .2;
    transition:  0.5s;
    text-align: center;

  }

  .containerR .card:hover .face.face1 .content{
    opacity: 1;

  }

  .containerR .card .face.face1 .content i{
    font-size: 3em;
    color: white;
    display: inline-block;

  }

  .containerR .card .face.face1 .content h3{
    font-size: 1em;
    color: white;
    text-align: center;


  }

  .containerR .card .face.face1 .content a{
     transition: .5s;
  }

  .containerR .card .face.face2{
     position: relative;
     background: whitesmoke;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0,0,0,.8);
    transform: translateY(-200px);
  }

  .containerR .card:hover .face.face2{
      transform: translateY(0);


  }

  .containerR .card .face.face2 .content p, a{
    font-size: 10pt;
    margin: 0 ;
    padding: 0;
    color:#333;
  }

  .containerR .card .face.face2 .content a{
    text-decoration:none;
    color: black;
    box-sizing: border-box;
    outline : 1px dashed #333;
    padding: 10px;
    margin: 15px 0 0;
    display: inline-block;
  }

  .containerR .card .face.face2 .content a:hover{
    background: #333 ;
    color: whitesmoke;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
  }


.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
}

.card {
    position: relative;
    width: 450px;
    height: 505px;
    background: #ffffff;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.poster {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.poster::before {
    content: '';
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: .3s;
}

.card:hover .poster::before {
    bottom: 0;
}

.poster img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
}

.card:hover .poster img {
    transform: scale(1.1);
}

.details {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1.5em 1.5em 2em;
    background: #000a;
    backdrop-filter: blur(16px) saturate(120%);
    transition: .3s;
    color: #fff;
    z-index: 2;
}

.card:hover .details {
    bottom: 0;
}

.details h1,
.details h2 {
    font-weight: 700;
}

.details h1 {
    font-size: 1.5em;
    margin-bottom: 5px;
}

.details h2 {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 10px;
    opacity: .6;
}

.details .rating {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    gap: .25em;
}

.details .rating i {
    color: #e3c414;
}

.details .rating span {
    margin-left: 0.25em;
}

.details .tags {
    display: flex;
    gap: .375em;
    margin-bottom: .875em;
    font-size: .85em;
}

.details .tags span {
    padding: .35rem .65rem;
    color: #fff;
    border: 1.5px solid rgba(255 255 255 / 0.4);
    border-radius: 4px;
    border-radius: 50px;
}

.details .desc {
    color: #fff;
    opacity: .8;
    line-height: 1.5;
    margin-bottom: 1em;
}

.details .cast h3 {
    margin-bottom: .5em;
}

.details .cast ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    width: 100%;
}

.details .cast ul li {
    list-style: none;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    border: 1.5px solid #fff;
}

.details .cast ul li img {
    width: 100%;
    height: 100%;
}

.alert {
    padding: 1em;
    margin: 1em 0;
    border-radius: 5px;
    background-color: #f0f4f8;
    border: 1px solid #d1e7dd;
    color: #0f5132;
}



.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 150px 0;
    position: relative;
    height: 100vh;
    overflow: hidden;
    background: radial-gradient(
      circle,
      hsla(30, 100%, 8%, 1) 0%,
      rgb(0, 0, 0) 100%
    );
  }

  .hero-copy {
    text-align: center;
    color: var(--white);
    max-width: 750px;
    z-index: 10;

    h1 {
      font-size: 76px;
      margin: 0 0 30px;
      font-weight: 300;

      span {
        background: linear-gradient(30deg, #ff8000 40%, var(--red) 70%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 300;
    }

    a {
      margin-top: 20px;
      display: inline-block;
      color: var(--white);
      text-decoration: none;
      font-size: 20px;
      border-radius: 8px;
      background: var(--red);
      padding: 20px 30px;
    }
  }

  .icon-layer {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .icon {
    position: absolute;
    border-radius: 12px;
    transition: transform 1s ease-out;
    animation: iconFadeIn 7s ease forwards;
    padding: 10px;

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      color: var(--white);
    }

    &.outline {
      border: 1px solid var(--gray);
    }

    &.fill {
      background: var(--gray);
    }

    &.sm {
      height: 40px;
      width: 40px;
      z-index: 1;
    }

    &.md {
      height: 60px;
      width: 60px;
      z-index: 3;
    }

    &.lg {
      height: 80px;
      width: 80px;
      z-index: 5;
    }
  }

  @keyframes iconFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.6;
    }
  }


.custom-circle {
    width: 2rem; /* Taille du cercle */
    height: 2rem; /* Taille du cercle */
    background-color: var(--custom-blue); /* Couleur de fond */
    border-radius: 50%; /* Pour le rendre circulaire */
    left: -1rem; /* Ajuste ce décalage à gauche */
    top: 50%; /* Centré verticalement */
    transform: translateY(-50%); /* Ajuste pour centrer exactement */
    z-index: 0; /* Position de l'élément derrière le texte */
}

#tsparticles {
    height: 100vh;
    width: 100vw;
  }

  .bg-image {
    background-image: url('../assets/images/contactBack.webp'); /* Remplacez par le chemin de votre image */
    background-size: cover; /* Pour que l'image couvre toute la section */
    background-position: center; /* Pour centrer l'image */
    background-repeat: no-repeat; /* Pour éviter que l'image se répète */
    background-blend-mode: overlay;
}


@media only screen and (min-width: 768px) {
  #methodologie .marginPicOnDesktop {
    margin-top: -290px;
  }
}