@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Comfortaa', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


  .App-header {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 10;
    transition: background-color 0.5s ease, height 0.5s ease;
  }
  .logo {
    transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease;
  }
  .next-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px;
    height: 100vh;
    color: #000; /* S'assurer que la couleur du texte est visible */
    transition: background-color 0.5s ease;
  }
  .next-section h1 {
    font-size: 3em;
  }
  .next-section p {
    font-size: 1.5em;
    padding: 10px;
  }

  .icon-animation {
    transition: transform 0.3s ease;
}

.icon-animation:hover {
    transform: scale(1.1);
    animation: bounce 0.5s;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
/* .timeline::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #FFD700;
    transform: translateX(-50%);
}

.timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background: #ddd;
  transform: translateX(-50%);
}

.timeline-event {
  position: relative;
  width: 45%;
  margin: 20px 0;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-event-right {
  float: right;
  clear: right;
  text-align: left;
}

.timeline-event-left {
  float: left;
  clear: left;
  text-align: left;
}

.timeline-event .timeline-time {
  position: absolute;
  top: 0;
  left: -70px;
  font-size: 0.9em;
  color: #777;
}

.timeline-event .timeline-icon {
  position: absolute;
  top: 50%;
  left: -15px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  border: 2px solid #ddd;
  transform: translateY(-50%);
}

.timeline-event-right .timeline-time {
  left: auto;
  right: -70px;
}

.timeline-event-right .timeline-icon {
  left: auto;
  right: -15px;
}

@media screen and (max-width: 768px) {
  .timeline:before {
    left: 20px;
  }

  .timeline-event {
    width: 100%;
    padding-left: 50px;
    margin-left: 0;
    text-align: left;
  }

  .timeline-event-right,
  .timeline-event-left {
    float: none;
    clear: both;
  }

  .timeline-event .timeline-time {
    left: 0;
  }

  .timeline-event .timeline-icon {
    left: 0;
  }
}
 */

 #methodologie {
    position: relative;
  }

  .timeline-vertical {
    position: relative;
    width: 6px; /* Largeur de la ligne */
    background-color: #F9CF36; /* Couleur de la ligne */
    height: 500px; /* Hauteur de la ligne */
    margin: 0 auto; /* Centrer la ligne */
  }

  .timeline-vertical::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    background-color: #F9CF36;
    transform: translateX(-50%);
  }



  .timeline-content.left {
    position: relative;

    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 60px;
    float: left;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    .timeline-vertical {
      height: 300px; /* Ajuster la hauteur pour mobile */
    }

    .timeline-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }

    .timeline-icon i {
      font-size: 16px; /* Réduire la taille des icônes pour mobile */
    }
  }


  .timeline-item-right {
    position: relative;
    margin: 20px 0;
  }

  .timeline-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    border: 2px solid #FFD700;
    z-index: 1;
  }

  .timeline-content-right {
    position: relative;
    padding: 20px;
    width: 70vh;
    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 60px;
    float: left;
    text-align: left;
  }


  .timeline-item-left {
    position: relative;
    margin: 20px 0;
  }

  .timeline-content-left {
    position: relative;
    margin-top:155px;
    padding: 20px;
    width: 70vh;
    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 60px;
    float: right;
    text-align: left;
  }


  .my-vertical-progress {
    --bookmark-v-gap: 40px;
    --bookmark-l-bg: hsl(229.1, 10.6%, 30.8%);
    --bookmark-r-bg: hsl(229.1, 10.6%, 50.8%);
    --marker-size: 30px;
    --marker-bg: hsl(172.8, 28.5%, 53.9%);
    --thread-bg: hsl(172.8, 28.5%, 53.9%);
    /* max-width: 700px; */
    margin: 0 auto;
  }

  .my-vertical-progress .timeline-item {
    color: #000000;
    font-size: 9px;
    padding: 10px 20px;
  }

  @media screen and (max-width: 640px) {
    .timeline-wrapper {
      margin: 0 20px;
    }
  }

  .date {
    color: #71b26e;
    font-size: 10px;
    font-family: "Noto Sans";
    font-weight: 600;
    line-height: 13.62px;
  }
